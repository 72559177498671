<script setup>
import moment from 'moment';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { reactive, ref, computed, onMounted, defineAsyncComponent, defineEmits, defineProps } from 'vue';
import ProductService from '../../services/ProductService';
import GoogleService from '../../services/GoogleService';
import ZdhcServiceV2 from '../../services/Zdhc/ZdhcServiceV2';
import useMatchAlgorithm from '../../composables/useMatchAlgorithm';
import SnackbarComposables from '../../composables/SnackbarComposables';
// import SuggestionService from '../../services/SuggestionService';
// import ZdhcProductService from '../../services/ZdhcProductService';
/* Components */
const MatchModal = defineAsyncComponent( () => import ('../../components/modals/MatchModal'));
const MatchesModal = defineAsyncComponent( () => import ('../../components/modals/MatchesModal'));
/* Emits */
const emits = defineEmits(['productDeleted', 'selectProduct']);
/* Props */
const props = defineProps({
    parent_product:
        {
            required:true
        },
        isSelected:
        {
            required: false,
            type: Boolean,
            default: false
        }
});
/* Data */
const {t} = useI18n();
//const matches = ref([]);
const store = useStore();
const status = ref('void');
const product = reactive({});
const splittedName = ref([]);
const zdhc_matches = ref([]);
const local_matches = ref([]);
const deleteModal = ref(false);
const matchesModal = ref(false);
const showMatchModal = ref(false);
const suggestions_matches = ref([]);
const manualSearchModal = ref(false);
const { addSnackbar } = SnackbarComposables();
const { translate } = GoogleService();
const { storeExactMatch } = ProductService();
const { searchZdhcProductByWord } = ZdhcServiceV2();
const { checkExistingMatch } = useMatchAlgorithm();
// const { searchMatchById, searchMatchByWord } = ZdhcProductService();
//const { searchSuggestionById, searchSuggestionByName } = SuggestionService();
/* Computed */
const setStatusClasses = computed( () => 
{
    switch(status.value)
    {
        case 'void':
            return 'bg-blue-500 text-white';
        case 'success':
            return 'bg-green-50 text-green-500';
        case 'notFound': case 'actionRequired':
            return 'bg-orange-50 text-deep-orange-500';
        case 'suggestion':
            return 'bg-light-blue-50 text-light-blue-500';
        case 'manualNoMatch':
            return 'bg-red-50 text-red-500';
        default:
            return 'bg-blue-500 text-white';
            
    }
});
const setStatusContent = computed( () => 
{
    switch(status.value)
    {
        case 'void':
            return '<svg class="animate-spin w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>';
        case 'success':
            return 'Match';
        case 'suggestion':
            return 'Manual Match';
        case 'notFound': case 'actionRequired':
            return 'Action required';
        case 'manualNoMatch':
            return 'No match';
        default:
            return '<svg class="animate-spin fill-current w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>';
    }
});
const setReferenceDate = computed( () => 
{
    return product.incheck_reference && product.incheck_reference.reference_date 
            ? moment(product.incheck_reference.reference_date).format('MMMM YYYY') : '-';
});
const setCreatedAtDate = computed( () => 
{
    return product.created_at ? moment(product.created_at).format('YYYY-MM-DD') : '-';
});
const setFormulatorName = computed ( () => 
{
    return product.formulator 
            ? (product.formulator.name.length > 15 
                ? product.formulator.name.substring(0, 12)+'...' 
                : product.formulator.name) 
            : product.formulator_name 
                ? (product.formulator_name.length > 15
                    ? product.formulator_name.substring(0, 12)+'...'
                    :product.formulator_name)
                : '-';
});
const formulatorIsMatched = computed ( () => 
{
    return product.formulator 
            ? true 
            : false;
});
/* Methods */
const checkForExactMatch = async () => 
{
    const res = await checkExistingMatch(product);
    if(res)
    {
        if(res?.status === 'ok')
        {
            if(res.product) Object.assign(product, res.product);
            if(res.productStatus) status.value = res.productStatus;
        }
        /* if( status.value !== 'success') init(); */
        init();
    }
};
const init = async () => 
{

    let matchId = '';
    if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
    else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;
    /* const [local_res, zdhc_res, suggestion_res] = await Promise.all([ */
    let [zdhc_res] = await Promise.all([
        /*  searchMatchById(product.id), */
        searchZdhcProductByWord(product.name),
        /* searchSuggestionById(product.id) */
    ]);
    //Search for locale matches
    /* if(local_res)
    {
        local_matches.value = local_matches.value.concat(local_res);
    } */

    //Search for ZDHC matches
    if(zdhc_res)
    { 
        zdhc_res = zdhc_res.filter(p => 
        {
            return p.id === matchId || (p.formulator_name !== 'No match Formulator' && (p.fullProduct?.registered && p.fullProduct?.registered === 'ZDHC Gateway'));
        });
        zdhc_res.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') zdhc_matches.value.push(p);});
    }

    //Search for Suggestions
    /* if(suggestion_res)
    {
        if(suggestion_res.some( s => s.count > 15))
        {
            let p = suggestion_res.filter(s => s.count > 15)[0];
            if(p.count >= 15)
            {
                p.ProductID = p.id;
                p.productName = p.name;
                p.productGUID = p.guid;
                suggestions_matches.value.push(p);
            }
        }
        else 
        {
            suggestion_res.forEach( p => 
            {
                if(!suggestions_matches.value.some(s => s.id === p.id))
                {
                    suggestions_matches.value.push(p);
                }
            });
        }
    } */

    //if(local_res && zdhc_res && suggestion_res)
    if(zdhc_res)
    {
        // let res = finalCheck(local_res, zdhc_res, suggestion_res);
        let res = finalCheck([], zdhc_res, []);
        if(res === 'trueMatch')
        {
            status.value = 'success';
        }
        else if(res === 'multipleMatch')
        {
            if(product.incheck_reference.zdhc_product_id) return  status.value = 'success';
            else if(product.incheck_reference.suggestion_id) return  status.value = 'suggestion';
            else if(product.incheck_reference.no_match_id) return status.value = 'manualNoMatch';
            else return status.value = 'actionRequired';
        }
        else if(res === 'noMatch')
        {
            //no Maches -> The algorithm search starts
            algorithmFirstPhase();
        }
    }
};
const algorithmFirstPhase = async () => 
{
    // Phase 1 - Translate the product name (EN)
    let matchId = '';
    if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
    else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;
    let translate_res = await translate(product.name);
    if(translate_res)
    {
        let translated_name = translate_res.data.translations[0].translatedText;
        // Phase 2 
        //Search for locale matches
        /* let local_res = await searchMatchByWord(translated_name);
        if(local_res)
        {
            local_res.forEach( p => local_matches.value.push(p));
        } */
        // Search match into zdhc records 
        let zdhc_res = await searchZdhcProductByWord(translated_name);
        if(zdhc_res)
        {
            zdhc_res = zdhc_res.filter(p => 
            {
                return p.id === matchId || (p.formulator_name !== 'No match Formulator' && (p.fullProduct?.registered && p.fullProduct?.registered === 'ZDHC Gateway'));
            });
            zdhc_res.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') zdhc_matches.value.push(p);});
        }
        // Search suggestions
        /* let suggestion_res = await searchSuggestionByName(translated_name);
        if(suggestion_res)
        {
            if(suggestion_res.some( s => s.count > 15))
            {
                let p = suggestion_res.filter(s => s.count > 15)[0];
                if(p.count >= 15)
                {
                    
                    let name = JSON.parse(p.zdhc_product.name);
                    p.ProductID = p.zdhc_product_id;
                    p.productName = name[store.state.locale] ? name[store.state.locale] : name.en;
                    p.productGUID = p.zdhc_product.guid;

                    suggestions_matches.value.push(p);
                }
            }
            else 
            {
                suggestion_res.forEach( p => 
                {
                    
                    if(!suggestions_matches.value.some(s => s.zdhc_product_id === p.zdhc_product_id))
                    {
                        suggestions_matches.value.push(p);
                    }
                });
            }
        } */

        // if(local_res && zdhc_res && suggestion_res)
        if(zdhc_res)
        {
            let res = finalCheck([], zdhc_res, []);
            if(res === 'trueMatch')
            {
                status.value = 'success';
            }
            else if(res === 'multipleMatch')
            {
                if(product.incheck_reference.zdhc_product_id) return  status.value = 'success';
                else if(product.incheck_reference.suggestion_id) return  status.value = 'suggestion';
                else if(product.incheck_reference.no_match_id) return status.value = 'manualNoMatch';
                else return status.value = 'actionRequired';
            }
            else
            {
                //no Maches -> Algorithm 2nd Phase
                algorithmSecondPhase();
            }

        }
    }
};
const algorithmSecondPhase = async () => 
{
    let matchId = '';
    if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
    else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;
    splittedName.value = splittedName.value.filter( s => s.length > 2);
    const totalLength =  splittedName.value.length;
    let actualResLength = 0;
    let totalZdhcRes = [];
    //let totalSuggestionsRes = [];
    if(totalLength)
    {
        splittedName.value.forEach( async (splitted_word/* , index */) => 
        {
            splitted_word = splitted_word.toLowerCase();
            // Get matches for 'word'
            // const [local_res, zdhc_res, suggestion_res] = await Promise.all([
            let [zdhc_res] = await Promise.all([
                /* searchMatchByWord(splitted_word), */
                searchZdhcProductByWord(splitted_word),
                /* searchSuggestionByName(splitted_word) */
            ]);
            /* if(local_res.length)
            {
                local_res.forEach( p => local_matches.value.push(p));
            } */
            // ZDHC matches
            if(zdhc_res.length) 
            {
                zdhc_res = zdhc_res.filter(p => 
                {
                    return p.id === matchId || (p.formulator_name !== 'No match Formulator' && (p.fullProduct?.registered && p.fullProduct?.registered === 'ZDHC Gateway'));
                });
                zdhc_res.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') zdhc_matches.value.push(p);});
                totalZdhcRes = [...totalZdhcRes, ...zdhc_res];
            }
            // Suggestions matches
            /* if(suggestion_res.length) 
            {
                suggestion_res.forEach( p => 
                {
                    if(!suggestions_matches.value.some(s => s.zdhc_product_id === p.zdhc_product_id))
                    {
                        suggestions_matches.value.push(p);
                    }
                });
                totalSuggestionsRes = [...totalSuggestionsRes, ...suggestion_res];
                // if(suggestion_res.some( s => s.count > 15))
                // {
                //     let p = suggestion_res.filter(s => s.count > 15)[0];
                //     if(p.count >= 15)
                //     {
                //         let name = JSON.parse(p.zdhc_product.name);
                //         p.ProductID = p.zdhc_product_id;
                //         p.productName = name[store.state.locale] ? name[store.state.locale] : name.en;
                //         p.productGUID = p.zdhc_product.guid;

                //         suggestions_matches.value.push(p);
                //     }
                // }
                // else 
                // {
                //     suggestion_res.forEach( p => 
                //     {
                //         if(!suggestions_matches.value.some(s => s.zdhc_product_id === p.zdhc_product_id))
                //         {
                //             suggestions_matches.value.push(p);
                //         }
                //     });
                // }
            } */
            //if(local_res || zdhc_res || suggestion_res)
            if(zdhc_res)
            {
                actualResLength +=1;
            }
            if(actualResLength === totalLength)
            {
                //const res = finalCheck(local_res, totalZdhcRes, totalSuggestionsRes);
                const res = finalCheck([], totalZdhcRes, []);
                if(res === 'trueMatch')
                {
                    status.value = 'success';
                }
                else if(res === 'multipleMatch')
                {
                    if(product.incheck_reference.zdhc_product_id) return  status.value = 'success';
                    else if(product.incheck_reference.suggestion_id) return  status.value = 'suggestion';
                    else if(product.incheck_reference.no_match_id) return status.value = 'manualNoMatch';
                    else return status.value = 'actionRequired';
                }
                else
                {
                    if(product.incheck_reference.zdhc_product_id) return  status.value = 'success';
                    else if(product.incheck_reference.no_match_id) return status.value = 'manualNoMatch';
                    else if(product.incheck_reference.suggestion_id) status.value = 'suggestion';
                    else  status.value = 'notFound';
                }
            }
            /* else 
            {
                status.value= "notFound";
                if(splitted_word.length > 3)
                {
                    splittedName.value[index] = sliceWord(splitted_word);
                    algorithmSecondPhase();
                }
                else 
                {
                    status.value= "notFound";
                }
            } */
        });
    }
    else 
    {
        status.value= "notFound"; 
    }
};
/* const sliceWord = (word) => 
{
    return word.slice(1, -1);
}; */
const finalCheck = (local_res, zdhc_res, suggestion_res) => 
{
    /* Single Match */
    if(local_res.length === 1 || zdhc_res.length === 1 || suggestion_res.length === 1)
    {
        let true_match = null;
        // Exact match from ZDHC
        if(zdhc_res.length === 1)
        {
            true_match = zdhc_res[0];
        }
        // Exact match from locale (with no results from ZDHC)
        else if(!zdhc_res.length && local_res.length === 1)
        {
            true_match = local_res[0];
        }
        // Suggestion
        else if(zdhc_res.length !== 1 &&  suggestion_res.length === 1)
        {
            if(suggestion_res[0].count >= 15)
            {
                true_match = suggestion_res[0];
            }
            /* else 
            {
                storeSimpleSuggestion(product.id, {ProductID: suggestion_res[0].id})
                .then(res => 
                { 
                    if(res.suggestion)
                    {
                        product.incheck_reference.suggestion_id = res.suggestion.id;
                        product.incheck_reference.suggestion = res.suggestion;
                    }
                });
                return status.value = 'suggestion';
            } */
        }
    
        if(true_match) 
        {
            // It is a true match only if it contains the same name and the same formulator
            if(true_match.fullProduct &&  product.formulator
                && true_match.fullProduct.formulatorGUID
                && product.formulator.guid
                && true_match.fullProduct.formulatorGUID === product.formulator.guid
                && true_match.name === product.name)
            {
                true_match.reference_date = product.incheck_reference.reference_date;
                storeExactMatch(product.id, true_match)
                .then(res => 
                {
                    product.incheck_references = res.data.p.incheck_references;
                    product.incheck_reference = res.data.p.incheck_reference;
                    product.formulator = res.data.p.formulator;
                });
                /* product.incheck_reference.match = true_match;
                product.incheck_reference.zdhc_product_id = true_match.id; */
                return 'trueMatch';
            }
            return 'multipleMatch';
        }
    }
    /* Multiple matches */
    if(local_res.length > 1 || zdhc_res.length > 1 || suggestion_res.length > 1)
    {
        return 'multipleMatch';
    }
    
    return 'noMatch';
};
const closeMatchesModal = () => 
{
    matchesModal.value = false
};
const closeManualSearchModal = () => 
{
    manualSearchModal.value = false
};
const closeDeleteProductModal = () => 
{
    deleteModal.value = false
};
const closeShowMatchModal = () => 
{
    showMatchModal.value = false;
};
const updateProductSuggestion = (suggestion) => 
{
    product.incheck_reference.suggestion = suggestion;
    product.incheck_reference.suggestion_id = suggestion.id; 
    product.incheck_reference.no_match_id = null; 
    if(!suggestions_matches.value.some(s => s.zdhc_product_id === suggestion.zdhc_product_id))
    {
        let jsonName = JSON.parse(suggestion.zdhc_product.name)
        suggestions_matches.value.push(
            {
                name: jsonName[store.state.locale] ? jsonName[store.state.locale].toLowerCase() 
                : (jsonName['en'] ? jsonName['en'].toLowerCase() : jsonName[Object.keys(jsonName)[0]].toLowerCase()),
                id: suggestion.zdhc_product.id,
                guid: suggestion.zdhc_product.guid,
                count: suggestion.count
            }
        )
    }
    matchesModal.value = false;
    manualSearchModal.value = false;
    status.value = 'suggestion';
};
const updateProductNoMatch = (noMatchGuid) => 
{
    product.incheck_reference.no_match_id = noMatchGuid;
    status.value = "manualNoMatch";
    closeMatchesModal();
    closeManualSearchModal();
};
const productDeleted = (id) => 
{
    emits('productDeleted', id)
};
const checkForInventory = () => 
{
    let hasInventory = false;
    let incheck_date = moment(product.incheck_reference.reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
    if(product.inventories.length)
    {
        hasInventory = product.inventories.some( i => 
        {
            let inventory_date = moment(i.reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            return moment(inventory_date).isSame(moment(incheck_date));
        });
    }
    return hasInventory;
};
const handleButtonClick = () => 
{
    if(['notFound', 'actionRequired'].includes(status.value))
    {
        return status.value === 'notFound' ? manualSearchModal.value = true : matchesModal.value = true;
    }
    else if(status.value === 'manualNoMatch')
    {
        if(local_matches.value.length || zdhc_matches.value.length || suggestions_matches.value.length)
        {
            return matchesModal.value = true;
        }
        return manualSearchModal.value = true;
    }
    else if(['suggestion', 'success'].includes(status.value))
    {
        let productHasInventory = checkForInventory();
        if(productHasInventory)
        {
            return showMatchModal.value = true;
        }
        return matchesModal.value = true;
    }
    /* else if(['success'].includes(status.value))
    {
        return showMatchModal.value = true;
    } */
};
const openDeleteModal = () => 
{
    let hasInventory = checkForInventory();
    // If product is in inventory with acutal reference_date, cannot be destroyed
    if(hasInventory)
    {
        addSnackbar('warning', t('product.delete.hasInventory', store.state.locale))
    }
    else 
    {
        if(status.value !== 'void')
        {
            deleteModal.value = true;
        }
        else 
        {
            addSnackbar('warning', t('snackbar.wait-for-elimination', store.state.locale))
        }
    }
};
const selectProduct = () => 
{
    let hasInventory = checkForInventory();
    if(!hasInventory) emits('selectProduct', product);
};
/* Hooks */
moment.locale(store.state.locale);
onMounted( async () => 
{
    Object.assign(product, props.parent_product);
    product.incheck_reference = product.incheck_references[0];
    splittedName.value = props.parent_product.name.split(' ');
    if(Object.keys(product).length) checkForExactMatch();
});
</script>
<template>
    <tr v-if="Object.keys(product).length" class="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 duration-300"> 
        <td class="px-4 py-3">
            <button 
                class="w-5 h-5 rounded border text-white flex items-center justify-center"
                :class="isSelected ? 'bg-light-green-500 border-light-green-500' : 'bg-gray-100 dark:bg-gray-700 dark:border-gray-500'"
                @click.prevent="selectProduct">
                <svg v-show="isSelected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>    
            </button>
        </td>
        <!-- Product Id -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin">
                {{product.id}}
            </span>
        </td>
        <!-- Name -->
        <td class="px-4 py-3">
            <!--{{product.name}}-->
            <span v-html="product.name"></span>
        </td>
        <!-- Quantity -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin">
                {{ product.incheck_reference.quantity /100 }}
            </span>
        </td>
        <!-- Reference Date -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin capitalize">
                {{ setReferenceDate }}
            </span>
        </td>
        <!-- Formulator -->
        <td class="px-4 py-3 flex items-center justify-start space-x-1" :class="formulatorIsMatched ? 'text-light-green-500' : ''">
            <svg v-if="formulatorIsMatched" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 min-w-4 fill-current"><path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
            <span 
                class="text-sm font-thin">
                {{ setFormulatorName }}
            </span>
        </td>
        <!-- Status -->
        <td class="px-4 py-3">
            <!--<button 
                class="w-5 h-5 rounded-full p-1 flex items-center justify-center text-white" 
                :class="[setStatusClasses, {'cursor-default' : status !== 'actionRequired' && status !== 'suggestion' }]" v-html="setStatusContent"
                @click.prevent="matchesModal = true"
                :disabled="status !== 'actionRequired' && status !== 'suggestion'"></button>-->
            <button 
                class="py-1 px-2 text-xs rounded-full flex items-center justify-center whitespace-nowrap" 
                :class="[setStatusClasses, {'cursor-default' : !['notFound', 'suggestion', 'actionRequired', 'success', 'manualNoMatch'].includes(status) }]" v-html="setStatusContent"
                @click.prevent="handleButtonClick"
                :disabled="!['notFound', 'suggestion', 'actionRequired', 'success', 'manualNoMatch'].includes(status)"></button>
        </td>
        <!-- Created At -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin">
                {{ setCreatedAtDate }}
            </span>
        </td>
        <!-- Actions -->
        <td class="px-4 py-3">
            <div class="flex items-center justify-center">
                <router-link 
                    :to="`/${$store.state.locale}/product/edit/${product.id}`"
                    class="max-w-min rounded p-1 flex items-center justify-center text-white text-green-500 hover:text-white hover:bg-green-500 duration-300"
                    :title="$t('actions.edit', $store.state.locale)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4h6a1,1,0,0,0,0-2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM6,12.76V17a1,1,0,0,0,1,1h4.24a1,1,0,0,0,.71-.29l6.92-6.93h0L21.71,8a1,1,0,0,0,0-1.42L17.47,2.29a1,1,0,0,0-1.42,0L13.23,5.12h0L6.29,12.05A1,1,0,0,0,6,12.76ZM16.76,4.41l2.83,2.83L18.17,8.66,15.34,5.83ZM8,13.17l5.93-5.93,2.83,2.83L10.83,16H8Z"/></svg>
                </router-link>
                <button 
                    @click.prevent="openDeleteModal"
                    class="max-w-min rounded p-1 flex items-center justify-center text-white text-red-500 hover:text-white hover:bg-red-500 duration-300"
                    :title="$t('actions.delete', $store.state.locale)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"/></svg>
                </button>
            </div>
        </td>
    </tr>
    <teleport to="body">
        <!-- Matches Modal -->
        <MatchesModal 
            v-if="(local_matches.length >= 1 || zdhc_matches.length >= 1 || suggestions_matches.length >= 1) && matchesModal"
            :parent_local_matches="local_matches"
            :parent_zdhc_matches="zdhc_matches"
            :parent_suggestions_matches="suggestions_matches"
            :parent_product="product"
            @closeModal="closeMatchesModal"
            @newSuggestion="updateProductSuggestion"
            @noMatch="updateProductNoMatch"
        />
        <!-- Manual Search Modal -->
        <ManualSearchModal 
            v-if="manualSearchModal"
            :parent_product="product"
            @closeModal="closeManualSearchModal"
            @newSuggestion="updateProductSuggestion"
            @noMatch="updateProductNoMatch"
        />
        <!-- Delete Modal -->
        <DeleteProductModal 
            v-if="deleteModal"
            :parent_product="product"
            @closeModal="closeDeleteProductModal"
            @productDeleted="productDeleted"
        />
        <!-- Match Modal -->
        <MatchModal 
            v-if="showMatchModal"
            :parent_product="product"
            @closeModal="closeShowMatchModal"
        />
    </teleport>
</template>